import {useQuasar as useQuasar} from 'quasar/src/index.dev.js';

import { ErrorResponse } from '@/web/server/interfaces/Responses/ErrorResponse'
import type { fetchWithSsrProxy } from './fetchWithSsrProxy'

type FetchResult = Awaited<ReturnType<typeof fetchWithSsrProxy>>

export function useNotify() {
    const $q = useQuasar()

    const notifyError = (error: ErrorResponse | null) => {
        if (!error) {
            return
        }

        $q.notify({
            message: error.errorMessage,
            type: 'negative',
            position: 'bottom',
        })
    }

    const notifyResult = ({ error }: Pick<FetchResult, 'error'>, successMsg: string) => {
        if (error) {
            $q.notify({
                message: error.errorMessage,
                type: 'negative',
                position: 'bottom',
            })
        } else {
            $q.notify({
                message: successMsg,
                type: 'positive',
                position: 'bottom',
            })
        }
    }

    const notifyResults = (results: Array<FetchResult>, successMsg: string) => {
        const errors = results.filter((result) => result.error !== null).map((result) => result.error)
        if (errors.length === 0) {
            $q.notify({
                message: successMsg,
                type: 'positive',
                position: 'bottom',
            })
        } else {
            for (const error of errors) {
                $q.notify({
                    message: error.errorMessage,
                    type: 'negative',
                    position: 'bottom',
                })
            }
        }
    }

    return {
        notifyError,
        notifyResult,
        notifyResults,
    }
}
