import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useSeoMeta } from '@unhead/vue'
import { useUserStore } from '@/web/client/store/User/useUserStore'
import { RouteName } from '@/web/client/router/routes'
import LoginPageForm from './LoginPageForm.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  setup(__props) {

const userStore = useUserStore()
const router = useRouter()
onMounted(async() => {
    if (userStore.isLoggedIn) {
        await router.push({ name: RouteName.Admin })
    }
})

useSeoMeta({ title: 'Log In' })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("article", null, [
    _createVNode(LoginPageForm)
  ]))
}
}

})