import { QInput as _component_q_input } from 'quasar/src/index.dev.js';
import { QBtn as _component_q_btn } from 'quasar/src/index.dev.js';
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {QForm as QForm} from 'quasar/src/index.dev.js';

import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { RouteName } from '@/web/client/router/routes'
import { useUserStore } from '@/web/client/store/User/useUserStore'
import { useNotify } from '@/web/client/utils/useNotify'


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPageForm',
  setup(__props) {

const username = ref<string | null>(null)
const password = ref<string | null>(null)

const userStore = useUserStore()
const router = useRouter()
const { notifyError } = useNotify()
const formRef = ref<QForm | null>(null)
const login = async() => {
    if (!formRef.value) {
        return
    }

    if (!await formRef.value.validate()) {
        return
    }

    if (!username.value || !password.value) {
        return
    }

    const { error } = await userStore.login(username.value, password.value)
    if (error) {
        notifyError(error)
        return
    }

    await router.push({ name: RouteName.Admin })
}

return (_ctx: any,_cache: any) => {
  
  

  return (_openBlock(), _createBlock(_unref(QForm), {
    ref_key: "formRef",
    ref: formRef,
    autocorrect: "off",
    autocapitalize: "off",
    spellcheck: "false"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_input, {
        modelValue: username.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((username).value = $event)),
        outlined: "",
        label: "Username",
        autocomplete: "username",
        "hide-bottom-space": "",
        "lazy-rules": "ondemand",
        rules: [
                (val) => Boolean(val) || 'Field is required',
            ]
      }, null, 8, ["modelValue", "rules"]),
      _createVNode(_component_q_input, {
        modelValue: password.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
        outlined: "",
        label: "Password",
        type: "password",
        autocomplete: "password",
        "hide-bottom-space": "",
        "lazy-rules": "ondemand",
        rules: [
                (val) => Boolean(val) || 'Field is required',
            ]
      }, null, 8, ["modelValue", "rules"]),
      _createVNode(_component_q_btn, {
        unelevated: "",
        class: "login-btn",
        color: "primary",
        "text-color": "white",
        label: "Log In",
        onClick: login
      })
    ]),
    _: 1
  }, 512))
}
}

})